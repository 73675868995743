<template>
  <div class="">
    <div
      v-if="!toggle"
      class="mt-2"
      :class="{'mb-2': content.title, 'mb-0': !content.title}">
      <span v-if="method === 'edit'">
        <fa
          icon="pencil-alt"
          class="text-warning mr-2 pointer"
          @click="toggle = !toggle">
        </fa>
        <fa
          icon="plus"
          v-if="level < 3"
          class="text-success mx-2 pointer"
          @click="createContent">
        </fa>
        <fa
          icon="arrow-down"
          class="text-primary pointer mx-2 float-right"
          @click="moveContent(content.id, 1)">
        </fa>
        <fa
          icon="arrow-up"
          class="text-primary pointer mx-2 float-right"
          @click="moveContent(content.id, -1)">
        </fa>
      </span>
    </div>
    <div class="" v-if="!toggle">
      <div class="">
        <div class="row">
          <div
            v-if="content.title"
            class="col-12 pl-2"
            style="white-space: pre-line;">
            <h6>{{content.title}}</h6>
          </div>

          <div
            v-if="content.content"
            class="col-12 mt-2 pl-4"
            style="white-space: pre-line;">
            {{content.content}}
          </div>

          <DetailContentTable
            v-if="content.tableContent"
            class="col-12 mt-2"
            :value="content.tableContent">
          </DetailContentTable>

          <DetailContentImage
            v-if="content.imageContent"
            class="col-12 mt-2"
            :src="content.imageContentPreview"
            :value="content.imageContent">
          </DetailContentImage>
        </div>
      </div>
    </div>

    <div class="mb-3 mt-2" v-if="toggle">
      <div class="">
        <fa
          icon="pencil-alt"
          class="text-warning mr-2 pointer"
          @click="toggle = !toggle">
        </fa>
      </div>

      <div class="form-row">
        <sgv-input-text
          label="หัวข้อ"
          v-model="formData.title"
          class="col-12">
        </sgv-input-text>

        <sgv-input-textarea
          label="เนื้อหา"
          v-model="formData.content"
          class="col-12"
          auto-grow>
        </sgv-input-textarea>

        <sgv-input-textarea
          label="ตาราง"
          v-model="formData.tableContent"
          class="col-12"
          auto-grow>
        </sgv-input-textarea>

        <sgv-input-textarea
          label="รูปภาพ"
          v-model="formData.imageContent"
          class="col-12"
          auto-grow>
        </sgv-input-textarea>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <sgv-input-check
            label="ขึ้นหน้าใหม่"
            inline
            v-model="formData.isPageBreak">
          </sgv-input-check>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-warning"
        @click="updateContent(content.id)">
        แก้ไข
      </button>

      <button
        type="button"
        class="btn btn-danger float-right"
        @click="destroyContent(content.id)">
        ลบ
      </button>
    </div>

    <div class="row mx-2" v-if="level < 3">
      <div class="col-12">
        <DetailContent
          class=""
          v-for="child in content.children"
          :key="child.id"
          :level="level+1"
          :paperId="paperId"
          :paperType="paperType"
          :templateType="templateType"
          :content="child"
          :method="method"
          @updated="emitUpdated">
        </DetailContent>
      </div>
    </div>
  </div>
</template>

<script>
import {
  UPDATE_CONTENT,
  DESTROY_CONTENT,
  MOVE_CONTENT,
  CREATE_CONTENT
} from './graph'
import DetailContentTable from './DetailContentTable'
import DetailContentImage from './DetailContentImage'

export default {
  name: 'DetailContent',
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    level: {
      type: Number,
      required: true
    },
    content: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        title: '',
        content: '',
        imageContent: '',
        tableContent: '',
        isPageBreak: false
      }
    }
  },
  methods: {
    createContent () {
      this.$apollo.mutate({
        mutation: CREATE_CONTENT(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          input: {
            title: '',
            content: '',
            imageContent: '',
            tableContent: '',
            parentId: this.content.id,
            isPageBreak: false
          }
        }
      })
      .then(() => {
        this.emitUpdated()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateContent (id) {
      this.$apollo.mutate({
        mutation: UPDATE_CONTENT(this.templateType),
        variables: {
          paperType: this.paperType,
          contentId: id,
          input: this.formData
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
        this.toggle = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyContent (id) {
      this.$apollo.mutate({
        mutation: DESTROY_CONTENT(this.templateType),
        variables: {
          paperType: this.paperType,
          contentId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    moveContent (id, step) {
      this.$apollo.mutate({
        mutation: MOVE_CONTENT(this.templateType),
        variables: {
          paperType: this.paperType,
          contentId: id,
          step
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
      })
      .catch(this.$toasted.global.error)
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  watch: {
    content: {
      handler (v) {
        this.formData.title = v.title
        this.formData.content = v.content
        this.formData.imageContent = v.imageContent
        this.formData.tableContent = v.tableContent
        this.formData.isPageBreak = v.isPageBreak
      },
      immediate: true
    }
  },
  components: {
    DetailContentTable,
    DetailContentImage
  }
}
</script>

<style lang="css" scoped>
</style>
