<template>
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr>
          <td
            v-for="(header, idh) in headers"
            :key="idh"
            class="text-center">
            {{header}}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, idx) in rows" :key="idx">
          <td
            v-for="(col, idc) in row"
            :key="idc"
            style="white-space: pre-line;"
            :class="{
              'text-center': col.alignment === 'center',
              'text-right': col.alignment === 'right',
            }">
            {{col.text}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'DetailContentTable',
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {

    }
  },
  computed: {
    txtSplit () {
      if (!this.value) return []
      return this.value.split('\n').map(v => v.trim()).filter(v => v)
    },
    headers () {
      if (this.txtSplit.length === 0) return []
      return this.txtSplit[0].split('|').map(v => v.trim()).filter(v => v)
    },
    columns () {
      if (this.txtSplit.length <= 1) return []
      return this.txtSplit[1].split('|').map(v => v.trim()).filter(v => v)
    },
    rows () {
      if (this.txtSplit.length <= 2) return []
      return this.txtSplit.slice(2).map(row => {
        const cols = row.split('|').map(v => v.trim()).filter(v => v)

        return cols.map((col, idx) => {
          let alignment = 'left'

          if (this.columns[idx] === ':-:') alignment = 'center'
          else if (this.columns[idx] === '--:') alignment = 'right'

          return {text: col, alignment}
        })
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
