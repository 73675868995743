<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <div>
      <sgv-form :method.sync="method" :options="options">
        <DetailForm
          :paperId="paperId"
          :formData="formData"
          :paperType="paperType"
          :templateType="templateType"
          :method="method"
          :v="$v"
          @updated="refetch">
        </DetailForm>
      </sgv-form>

      <template v-if="paperId !== 0">
        <HrDivider :options="tabs" v-model="selectedTab" class="mt-3"></HrDivider>

        <keep-alive>
          <div v-if="selectedTab === 'DetailContent'" class="row">
            <div class="col-12">
              <DetailContent
                class="mb-3"
                v-for="content in formData.contents"
                :key="content.id"
                :level="1"
                :paperId="paperId"
                :paperType="paperType"
                :templateType="templateType"
                :content="content"
                :method="method"
                @updated="dataDetail">
              </DetailContent>
            </div>

            <div class="col-12 mt-3" v-if="method === 'edit'">
              <button
                type="button"
                class="btn btn-success btn-block"
                @click="createContent">
                เพิ่มรายการ
              </button>
            </div>
          </div>

          <div v-else-if="selectedTab === 'DetailPrint'">
            <DetailPrint
              :paperId="paperId"
              :paperType="paperType"
              :templateType="templateType"
              :formData="formData"
              :url="`/paper/${paperType}/${paperId}/print`"
              :filename="`${formData.code}.pdf`">
            </DetailPrint>
          </div>

          <div v-else-if="selectedTab === 'DetailAttachment'">
            <DetailAttachment
              :method="method"
              :paperId="paperId"
              :paperType="paperType"
              :templateType="templateType"
              :canDestroy="!formData.reviewedAt">
            </DetailAttachment>
          </div>
        </keep-alive>
      </template>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm'
import DetailContent from './DetailContent'
import DetailAttachment from '@/views/paper_template/components/Attachment'
import DetailPrint from '@/views/paper_template/components/DetailPrint'
import {
  DETAIL_PAPER,
  CREATE_PAPER,
  UPDATE_PAPER,
  DESTROY_PAPER,
  CREATE_CONTENT
} from './graph'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formData: {
        code: '',
        name: '',
        isActive: true,
        reviewedAt: null,
        reviewedUser: {},
        approvedAt: null,
        approvedUser: {},
        contents: []
      },
      method: 'info',
      listView: `Paper${this.$form.capitalize(this.paperType)}List`,
      selectedTab: 'DetailContent',
      isLoaded: false,
      tabs: [
        {text: 'รายละเอียด', value: 'DetailContent'},
        {text: 'พิมพ์', value: 'DetailPrint'},
        {text: 'ไฟล์แนบ', value: 'DetailAttachment'},
      ]
    }
  },
  validations: {
    formData: {
      name: { required },
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.formData.reviewedAt || !this.$auth.hasRole(`paper:${this.paperType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.formData.reviewedAt || !this.$auth.hasRole(`paper:${this.paperType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    refetch () {
      this.dataDetail()
    },
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.paper)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.isLoaded = true
      })
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      return  {
        code: v.code,
        name: v.name,
        isActive: v.isActive
      }
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, paperId: res.data.paper.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.paper)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.listView) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
    createContent () {
      this.$apollo.mutate({
        mutation: CREATE_CONTENT(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          input: {
            title: 'หัวข้อใหม่...',
            content: '',
            imageContent: '',
            tableContent: ''
          }
        }
      })
      .then(() => {
        this.dataDetail()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  created () {
    if (this.paperId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
  },
  components: {
    DetailForm,
    DetailAttachment,
    DetailPrint,
    DetailContent
  }
}
</script>

<style lang="css">
</style>
